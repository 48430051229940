import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Courses";

const MODULE_TITLE = t("courses");

const NAME = "courses";

const route: RouteRecordRaw = {
  name: "CoursesManagement",
  path: "/courses/",
  component: RouterView,
  redirect: { name: "Courses" },
  meta: {
    breadcrumb: [{ title: t("courses"), routeName: "CoursesManagement" }],
  },
  children: [
    {
      path: "all-courses",
      name: "Courses",
      component: () => import("./screens/Courses.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
        ],
      },
    },
    {
      path: "create-course",
      name: "createCourse",
      component: () => import("./screens/CreateCourse.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("create-course"), routeName: "createCourse" },
        ],
      },
    },
    {
      path: "update-course/:id",
      name: "updateCourse",
      component: () => import("./screens/UpdateCourse.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("update-course"), routeName: "updateCourse" },
        ],
      },
    },
    {
      path: "view-course/:course_id",
      name: "viewCourse",
      component: () => import("./screens/ViewCourse.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("course-details"), routeName: "viewCourse" },
        ],
      },
    },
    {
      path: "import-course/:course_id",
      name: "importCourse",
      component: () => import("../ImportCourse/screens/ImportCourse.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("course-details"), routeName: "viewCourse" },
          { title: t("import-course"), routeName: "importCourse" },
        ],
      },
    },
    {
      path: "create-chapter/:course_id",
      name: "createChapter",
      component: () => import("./screens/CreateChapter.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("course-details"), routeName: "viewCourse" },
          { title: t("create-chapter"), routeName: "createChapter" },
        ],
      },
    },
    {
      path: "view-chapter/:course_id/:chapter_id",
      name: "viewChapter",
      component: () => import("./screens/ViewChapter.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("course-details"), routeName: "viewCourse" },
          { title: t("view-chapter"), routeName: "viewChapter" },
        ],
      },
    },
    {
      path: "update-chapter/:course_id/:chapter_id",
      name: "updateChapter",
      component: () => import("./screens/UpdateChapter.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("course-details"), routeName: "viewCourse" },
          { title: t("update-chapter"), routeName: "updateChapter" },
        ],
      },
    },
    {
      path: "create-lesson/:course_id/:chapter_id",
      name: "createLesson",
      component: () => import("./screens/CreateLesson.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("course-details"), routeName: "viewCourse" },
          { title: t("view-chapter"), routeName: "viewChapter" },
          { title: t("create-lesson"), routeName: "createLesson" },
        ],
      },
    },
    {
      path: "update-lesson/:course_id/:chapter_id/:lesson_id",
      name: "updateLesson",
      component: () => import("./screens/UpdateLesson.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("course-details"), routeName: "viewCourse" },
          { title: t("view-chapter"), routeName: "viewChapter" },
          { title: t("update-lesson"), routeName: "updateLesson" },
        ],
      },
    },
    {
      path: "view-lesson/:course_id/:chapter_id/:lesson_id",
      name: "viewLesson",
      component: () => import("./screens/ViewLesson.vue"),
      meta: {
        breadcrumb: [
          { title: t("courses"), routeName: "CoursesManagement" },
          { title: t("all-courses"), routeName: "Courses" },
          { title: t("course-details"), routeName: "viewCourse" },
          { title: t("view-chapter"), routeName: "viewChapter" },
          { title: t("view-lesson"), routeName: "viewLesson" },
        ],
      },
    },

    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

export { route, API_URL, MODULE_TITLE, NAME };
