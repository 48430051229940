import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";
import admins from "./admins";
import language from "./language";
import content from "./content";
import semester from "./semester";
import leaderboard from "./leaderboard";
import softDeleted from "./softDeleted";
import adminNotifications from "./adminNotifications";
import appNotification from "./appNotification";

const MODULE_TITLE = t("SettingsPanel");

const route: RouteRecordRaw = {
  path: "/settings/",
  name: "settings",
  component: RouterView,
  meta: {
    breadcrumb: [{ title: t("Settings"), routeName: "settings" }],
  },
  children: [
    {
      path: "settings-panel",
      name: "settings-panel",
      component: () => import("../screens/SettingsPanel.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          { title: t("SettingsPanel"), routeName: "settings-panel" },
        ],
      },
    },
    {
      path: "image-management",
      name: "image-management",
      component: () => import("../screens/ImageManagement.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          { title: t("image-management"), routeName: "image-management" },
        ],
      },
    },
    {
      path: "admins-management/",
      name: "admins-management",
      component: RouterView,
      redirect: "/settings/admins-management/index",
      meta: {
        // permission: 1,
      },
      children: [
        {
          path: "index",
          name: "admins-management-list",
          component: () => import("../screens/admins/AdminsManagement.vue"),
          meta: {
            breadcrumb: [
              { title: t("Settings"), routeName: "settings" },
              { title: t("AdminsManagement"), routeName: "admins-management" },
            ],
          },
        },
        {
          path: "create",
          name: "createAdmin",
          component: () => import("../screens/admins/CreateAdmin.vue"),
          meta: {
            breadcrumb: [
              { title: t("Settings"), routeName: "settings" },
              { title: t("AdminsManagement"), routeName: "admins-management" },
              { title: t("create-new-admin"), routeName: "createAdmin" },
            ],
          },
        },
        {
          path: "edit/:id",
          name: "editAdmin",
          component: () => import("../screens/admins/EditAdmin.vue"),
          meta: {
            breadcrumb: [
              { title: t("Settings"), routeName: "settings" },
              { title: t("AdminsManagement"), routeName: "admins-management" },
              { title: t("edit-admin-profile"), routeName: "editAdmin" },
            ],
          },
        },
        {
          path: "profile/:id",
          name: "adminProfile",
          component: () => import("../screens/admins/AdminProfile.vue"),
          meta: {
            breadcrumb: [
              { title: t("Settings"), routeName: "settings" },
              { title: t("AdminsManagement"), routeName: "admins-management" },
              { title: t("AdminProfile"), routeName: "adminProfile" },
            ],
          },
        },
      ],
    },
    {
      path: "app-notification-controls",
      name: "app-notification-controls",
      component: () => import("../screens/AppNotificationControls.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("AppNotificationControls"),
            routeName: "app-notification-controls",
          },
        ],
      },
    },
    {
      path: "admin-notification-controls",
      name: "admin-notification-controls",
      component: () => import("../screens/AdminNotificationControls.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("AdminNotificationControls"),
            routeName: "admin-notification-controls",
          },
        ],
      },
    },
    {
      path: "list-of-presets",
      name: "ListOfPresets",
      component: () =>
        import("../screens/createNotificationsFlow/ListPresets.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("AdminNotificationControls"),
            routeName: "admin-notification-controls",
          },
          {
            title: t("list-of-presets"),
            routeName: "ListOfPresets",
          },
        ],
      },
    },
    {
      path: "create-preset",
      name: "CreatePreset",
      component: () =>
        import("../screens/createNotificationsFlow/PresetForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("AdminNotificationControls"),
            routeName: "admin-notification-controls",
          },
          {
            title: t("list-of-presets"),
            routeName: "ListOfPresets",
          },
          {
            title: t("create-preset"),
            routeName: "CreatePreset",
          },
        ],
      },
    },
    {
      path: "edit-preset/:id",
      name: "EditPreset",
      component: () =>
        import("../screens/createNotificationsFlow/PresetForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("AdminNotificationControls"),
            routeName: "admin-notification-controls",
          },
          {
            title: t("list-of-presets"),
            routeName: "ListOfPresets",
          },
          {
            title: t("edit-preset"),
            routeName: "EditPreset",
          },
        ],
      },
    },
    {
      path: "notification-target-list",
      name: "TargetList",
      component: () =>
        import("../screens/createNotificationsFlow/TargetList.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("AdminNotificationControls"),
            routeName: "admin-notification-controls",
          },
          {
            title: t("list-of-presets"),
            routeName: "ListOfPresets",
          },
          {
            title: t("target-list"),
            routeName: "TargetList",
          },
        ],
      },
    },
    {
      path: "create-notification",
      name: "CreateNotification",
      component: () =>
        import("../screens/createNotificationsFlow/CreateNotification.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("AdminNotificationControls"),
            routeName: "admin-notification-controls",
          },
          {
            title: t("create-notification"),
            routeName: "CreateNotification",
          },
        ],
      },
    },
    {
      path: "view-notification/:id",
      name: "ViewNotification",
      component: () =>
        import("../screens/createNotificationsFlow/ViewNotification.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("AdminNotificationControls"),
            routeName: "admin-notification-controls",
          },
          {
            title: t("view-notification"),
            routeName: "ViewNotification",
          },
        ],
      },
    },
    {
      path: "soft-delete-management",
      name: "soft-delete-management",
      component: () => import("../screens/SoftDeleteManagement.vue"),
      meta: {
        breadcrumb: [
          { title: t("Settings"), routeName: "settings" },
          {
            title: t("SoftDeleteManagement"),
            routeName: "soft-delete-management",
          },
        ],
      },
    },
    {
      path: "",
      name: "Redirect",
      redirect: "settings-panel",
    },
  ],
};

export {
  route,
  MODULE_TITLE,
  admins,
  language,
  content,
  semester,
  leaderboard,
  softDeleted,
  adminNotifications,
  appNotification,
};
