import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import type { RouteRecordRaw } from "vue-router";

const route: RouteRecordRaw = {
  path: "/auth/",
  component: () => import("@/modules/auth/components/Layout.vue"),
  meta: {
    guest: true,
  },
  children: [
    {
      path: "login",
      name: "login",
      component: () => import("@/modules/auth/screens/Login.vue"),
      meta: {
        guest: true,
      },
    },
    {
      path: "otp",
      name: "OTP",
      component: () => import("@/modules/auth/screens/otp.vue"),
      meta: {
        guest: true,
      },
    },
    {
      path: "set-password/:otp",
      name: "setPassword",
      component: () => import("@/modules/auth/screens/setPassword.vue"),
      meta: {
        guest: true,
      },
    },

    {
      path: "",
      redirect: "/auth/login",
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

export { route };
