import { t } from "@/core/i18n";
import { RouteRecordRaw, RouterView } from "vue-router";

export const route: RouteRecordRaw = {
  name: "generalSubscription",
  path: "/general-subscription/",
  component: () => import("./screens/generalSubscription.vue"),
  meta: {
    breadcrumb: [
      {
        title: t("general-subscription"),
        routeName: "generalSubscription",
      },
    ],
  },
};
