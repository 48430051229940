export enum EventType {
  //
  // Identity
  None = 0,
  IdentityUserCreated = 1,
  IdentityUserVerified = 2,
  IdentityUserAvatarChanged = 3,
  IdentityUserDeviceAdded = 4,
  IdentityAdminCreated = 100,
  IdentityAdminVerified = 101,
  IdentityAdminUpdated = 102,
  IdentityAdminEnabled = 103,
  IdentityAdminDisabled = 104,
  IdentityAdminDeleted = 105,
  IdentityAdminPasswordReset = 106,

  CurriculumsCountryCreated = 200,
  CurriculumsCountryRenamed = 201,
  CurriculumsCountryDeleted = 202,
  CurriculumsCityCreated = 210,
  CurriculumsCityRenamed = 211,
  CurriculumsCityDeleted = 212,
  CurriculumsSchoolCreated = 220,
  CurriculumsSchoolRenamed = 221,
  CurriculumsSchoolGradesAssigned = 222,
  CurriculumsSchoolDeleted = 223,

  CurriculumsGradeCreated = 230,
  CurriculumsGradeUpdated = 231,
  CurriculumsGradeDeleted = 232,
  CurriculumsGradeSubjectsAssigned = 233,

  CurriculumsSubjectCreated = 240,
  CurriculumsSubjectUpdated = 241,
  CurriculumsSubjectDeleted = 242,

  CurriculumsStageCreated = 250,
  CurriculumsStageUpdated = 251,
  CurriculumsStageDeleted = 252,
  CurriculumsStagePublished = 253,
  CurriculumsStageUnPublished = 254,

  CurriculumsStageQuestionCreatedTypeConnectTheDots = 255,
  CurriculumsStageQuestionCreatedTypeFillInThe3Blanks = 256,
  CurriculumsStageQuestionCreatedTypeFillInTheBlank = 257,
  CurriculumsStageQuestionCreatedTypePhotoMcqAnswerText = 258,
  CurriculumsStageQuestionCreatedTypePhotoTrueOrFalse = 259,
  CurriculumsStageQuestionCreatedTypeSortPhoto = 260,
  CurriculumsStageQuestionCreatedTypeSortText = 261,
  CurriculumsStageQuestionCreatedTypeSpell = 262,
  CurriculumsStageQuestionCreatedTypeTextAnswerNumber = 263,
  CurriculumsStageQuestionCreatedTypeTextAnswerText = 264,
  CurriculumsStageQuestionCreatedTypeTextMcqAnswer2Photo = 265,
  CurriculumsStageQuestionCreatedTypeTextMcqAnswer3Photo = 266,
  CurriculumsStageQuestionCreatedTypeTextMcqAnswer4Photo = 267,
  CurriculumsStageQuestionCreatedTypeTextMcqAnswer6Photo = 268,
  CurriculumsStageQuestionCreatedTypeTextMcqAnswer8Photo = 269,
  CurriculumsStageQuestionCreatedTypeTextMcqAnswerText = 270,
  CurriculumsStageQuestionCreatedTypeTextTrueOrFalse = 271,

  CurriculumsConfigStageLimitsChanged = 290,

  GamesDifficultyCreated = 300,
  GamesDifficultyUpdated = 301,
  GamesDifficultyDeleted = 302,
  GamesDifficultyEnabled = 303,
  GamesDifficultyDisabled = 304,
  GamesDifficultyForUserUpdated = 305,
  GamesQuizStarted = 306,
  GamesQuizQuestionAnswered = 307,
  GamesQuizCompleted = 308,
  GamesUserLeveledUp = 309,

  PointsBadgeCreated = 400,
  PointsBadgeUpdated = 401,
  PointsBadgeDeleted = 402,
  PointsBadgeEnabled = 403,
  PointsBadgeDisabled = 404,
  PointsLevelFormulaChanged = 490,
}

export enum QuestionType {
  TextMcqAnswerText = 1,
  PhotoMcqAnswerText = 2,
  TextMcqAnswer2Photo = 3,
  TextMcqAnswer3Photo = 5,
  TextMcqAnswer4Photo = 6,
  TextMcqAnswer6Photo = 7,
  TextMcqAnswer8Photo = 8,
  TextTrueOrFalse = 9,
  PhotoTrueOrFalse = 10,
  QuestionAnswerText = 11,
  QuestionAnswerNumber = 12,
  SortText = 13,
  SortPhoto = 14,
  SpellTheWord = 15,
  ConnectTheDots = 16,
  FillInTheBlank = 17,
  FillInThe3Blanks = 18,
}

export enum Semester {
  BothSemesters = 0,
  FirstSemester = 1,
  SecondSemester = 2,
}

export const SemesterName = (semester: Semester) => {
  switch (semester) {
    case Semester.FirstSemester:
      return "First Semester";
    case Semester.SecondSemester:
      return "Second Semester";
    case Semester.BothSemesters:
      return "Both Semesters";
    default:
      return "Unknown";
  }
};

export enum CoreLanguage {
  Arabic = 0,
  English = 1,
}

export enum CoreUserType {
  None = 0,
  Student = 1,
  Parent = 2,
  Teacher = 3,
  Management = 4,
  Adults = 5,
}

export enum NotificationPriority {
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
}

export enum CoreAccountType {
  Guest = 0,
  Bedayat = 1,
  Email = 2,
  Facebook = 3,
  Google = 4,
}

export enum UserTypeIcon {
  Facebook = "Facebook",
  Google = "Google",
  Bedaya = "Bedaya",
  Management = "Management",
}

export enum handleSubjectTypes {
  remove = "remove",
  add = "add",
}

export enum AuditType {
  All = 0,
  System = 1,
  Admin = 2,
  Achievement = 3,
  Report = 4,
}

export enum Projection {
  None = 0,
  Admin = 1,
  Badge = 2,
  City = 3,
  Country = 4,
  Difficulty = 5,
  Grade = 6,
  Quiz = 7,
  School = 8,
  StageLimit = 9,
  Stage = 10,
  Subject = 11,
  User = 12,
}

export enum Permission {
  None = 0,
  //General Permissions
  UserManagement = 1 << 0,
  GradeManagement = 1 << 1,
  SubjectManagement = 1 << 2,
  ViewLeaderboard = 1 << 3,
  QuizManagement = 1 << 4,
  BadgeManagement = 1 << 5,
  ExamManagement = 1 << 6,
  SupportManagement = 1 << 7,
  ViewDashboard = 1 << 8,
  ExportReports = 1 << 9,
  SoftDelete = 1 << 10,

  SettingsDateTime = 1 << 11,
  SettingsSemester = 1 << 12,
  SettingsLeaderboardReset = 1 << 13,
  ContentManagement = 1 << 14,
  AdminManagement = 1 << 15,
  AppNotifications = 1 << 16,
  AdminNotifications = 1 << 17,
  RestoreItems = 1 << 18,
  // All = ~(-1 << 18)
}

export enum SuggestionStatus {
  Idle = 1,
  Accepted = 2,
  Rejected = 3,
}

export enum BugReportStatus {
  Waiting = 0,
  Solved = 1,
  OnHold = 2,
}

export enum BugReportCategory {
  None = 0,
  Support = 1,
  Technical = 2,
}

export enum ReportFileType {
  Excel = 1,
  Pdf = 2,
  Word = 3,
  Csv = 4,
}
export enum ExerciseType {
  Quiz = 1,
  Exam = 2,
  Exercise = 3,
  GuideBook = 4,
  Suggestion = 5,
}

export enum SourceType {
  Quiz = 1,
  Exam = 2,
  BookExercise = 3,
  GuideExercise = 4,
}

export enum PackageType {
  Course = 0,
  Package = 1,
}
