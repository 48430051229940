<template>
  <header
    class="absolute w-full py-2 h-[70px] px-5 z-10 shadow-sm col-span-12 bg-white border-b-[1px] border-tertiary flex items-center xl:justify-end"
  >
    <button class="lg:hidden" @click="toggleSidebar">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style="fill: rgba(0, 0, 0, 1);"
      >
        <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
      </svg>
    </button>
    <!-- header -->

    <div class="container z-50 flex items-center justify-end gap-5 relative">
      <button
        v-if="user"
        class="flex items-center justify-center gap-2 p-2 font-medium rounded-lg bg-tertiary"
      >
        <Image
          :src="user.photoUrl || ''"
          @click="() => $router.push({ name: 'AdminProfile' })"
          class="h-8 w-8 border border-white object-cover rounded-full"
        />
      </button>
      <button
        @click="logout"
        class="flex items-center justify-center gap-2 px-3 py-2 font-medium rounded-lg select-none bg-tertiary"
      >
        <Loading v-if="is_loading" />
        <template v-else>
          <LogoutIcon />
          <span>
            {{ $t("logout") }}
          </span>
        </template>
      </button>
    </div>
  </header>
</template>
<script lang="ts" setup>
import LogoutIcon from "@/core/components/icons/LogoutIcon.vue";
import { useAuth } from "@/modules/auth/stores/auth";
import { useI18n } from "vue-i18n";

const { logout, is_loading, user } = useAuth();
const { locale } = useI18n();

const emit = defineEmits(["toggleSidebar"]);

const toggleSidebar = () => {
  emit("toggleSidebar");
};

const toggleLocale = () => {
  locale.value = locale.value === "en" ? "ar" : "en";
  localStorage.setItem("locale", locale.value);
  window.location.reload();
};
</script>
