import { compressFile } from "./compressFile";
import { EventType } from "../enums";

export const isNotificationSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;

export const getFirebaseErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "البريد الإلكتروني غير صحيح";
    case "auth/user-disabled":
      return "المستخدم معطل";
    case "auth/user-not-found":
      return "المستخدم غير موجود";
    case "auth/wrong-password":
      return "كلمة المرور غير صحيحة";
    case "auth/email-already-in-use":
      return "البريد الإلكتروني مستخدم من قبل";
    case "auth/weak-password":
      return "كلمة المرور ضعيفة";
    case "auth/operation-not-allowed":
      return "العملية غير مسموحة";
    case "auth/account-exists-with-different-credential":
      return "الحساب موجود ببيانات مختلفة";
    case "auth/invalid-credential":
      return "بيانات غير صحيحة";
    case "auth/invalid-verification-code":
      return "كود التحقق غير صحيح";
    case "auth/invalid-verification-id":
      return "رقم التحقق غير صحيح";
    case "auth/missing-verification-code":
      return "كود التحقق مفقود";
    case "auth/too-many-requests":
      return "لقد تم ارسال الكثير من رسائل التحقق, يرجى المحاولة لاحقا";
    case "auth/credential-already-in-use":
      return "البيانات مستخدمة من قبل";
    case "auth/invalid-phone-number":
      return "رقم الهاتف غير صحيح";
    case "auth/missing-phone-number":
      return "رقم الهاتف مفقود";
    case "auth/quota-exceeded":
      return "تم تجاوز الحد المسموح به";
    case "auth/code-expired":
      return "انتهت صلاحية الكود ، يرجى إعادة إرسال الكود مرة أخرى";
    default:
      return "حدث خطأ غير معروف ، يرجى المحاولة لاحقا";
  }
};

export const formateResponse = (response: CoreResponseDTO) => {
  const pagination: Pagination = {
    count: response.pageSize * response?.totalPages,
    last_page: response?.totalPages,
    current_page: response?.currentPage,
    per_page: response?.pageSize,
  };

  let data;

  if (response.content instanceof Array) {
    data = response.content.map((item: any) => {
      return {
        ...item,
        createdAt: item.createdAt
          ? new Date(item.createdAt).toLocaleDateString()
          : "",
      };
    });
  } else {
    data = {
      ...response.content,
      createdAt: response?.content?.createdAt
        ? new Date(response.content.createdAt).toLocaleDateString()
        : "",
    };
  }

  return {
    data,
    pagination,
    message: response.message,
    traceId: response.traceId,
  };
};

export const formateDate = (seconds: number) => {
  try {
    return new Date(seconds * 1000).toLocaleDateString();
  } catch (error) {
    return "";
  }
};

export function setLocale(newLocale: string) {
  document.title = newLocale === "ar" ? "انجاز" : "Enjaaz";
  document.documentElement.setAttribute(
    "dir",
    newLocale === "ar" ? "rtl" : "ltr"
  );
  document.body.setAttribute("dir", newLocale === "ar" ? "rtl" : "ltr");
  document.documentElement.setAttribute("lang", newLocale);
  document.body.setAttribute("lang", newLocale);

  document.documentElement.style.setProperty(
    "font-family",
    newLocale === "ar" ? "'Almarai', sans-serif" : "'Poppins', sans-serif"
  );
  document.body.style.setProperty(
    "font-family",
    newLocale === "ar" ? "'Almarai', sans-serif" : "'Poppins', sans-serif"
  );
}

export const handleFileUpload = async (file: File) => {
  const compressedFile = file; // await compressFile(file);
  const path = URL.createObjectURL(compressedFile);
  const name = compressedFile.name.slice(0, 8);
  const size = compressedFile.size / 1000 + "KB";
  const type = compressedFile.type.split("/")[1];

  return { path, name, size, type, compressedFile };
};

export function calculateExp(
  currentLevel: number,
  levelBaseExperience: number,
  levelScalingFactor: number
): number {
  /*
    XP = B * L^S

    L => LEVEL
    B =>        int? LevelBaseExperience 
    S =>        decimal? LevelScalingFactor 
*/
  const xp = levelBaseExperience * Math.pow(currentLevel, levelScalingFactor);
  const remainder = xp % 10;
  const roundedXP =
    remainder < 2.5
      ? xp - remainder
      : remainder < 7.5
      ? xp - remainder + 5
      : xp - remainder + 10;
  return Number(roundedXP.toFixed());
}

export const getEventLogType = (type: EventType): string => {
  switch (type) {
    case EventType.IdentityUserCreated:
      return "Identity User Created";
    case EventType.IdentityUserVerified:
      return "Identity User Verified";
    case EventType.IdentityUserAvatarChanged:
      return "Identity User Avatar Changed";
    case EventType.IdentityUserDeviceAdded:
      return "Identity User Device Added";
    case EventType.IdentityAdminCreated:
      return "Identity Admin Created";
    case EventType.IdentityAdminVerified:
      return "Identity Admin Verified";
    case EventType.IdentityAdminUpdated:
      return "Identity Admin Updated";
    case EventType.IdentityAdminEnabled:
      return "Identity Admin Enabled";
    case EventType.IdentityAdminDisabled:
      return "Identity Admin Disabled";
    case EventType.IdentityAdminDeleted:
      return "Identity Admin Deleted";
    case EventType.IdentityAdminPasswordReset:
      return "Identity Admin Password Reset";
    case EventType.CurriculumsCountryCreated:
      return "Curriculums Country Created";
    case EventType.CurriculumsCountryRenamed:
      return "Curriculums Country Renamed";
    case EventType.CurriculumsCountryDeleted:
      return "Curriculums Country Deleted";
    case EventType.CurriculumsCityCreated:
      return "CurriculumsCityCreated";
    case EventType.CurriculumsCityRenamed:
      return "CurriculumsCityRenamed";
    case EventType.CurriculumsCityDeleted:
      return "CurriculumsCityDeleted";
    case EventType.CurriculumsSchoolCreated:
      return "CurriculumsSchoolCreated";
    case EventType.CurriculumsSchoolRenamed:
      return "CurriculumsSchoolRenamed";
    case EventType.CurriculumsSchoolGradesAssigned:
      return "CurriculumsSchoolGradesAssigned";
    case EventType.CurriculumsSchoolDeleted:
      return "CurriculumsSchoolDeleted";
    case EventType.CurriculumsGradeCreated:
      return "CurriculumsGradeCreated";
    case EventType.CurriculumsGradeUpdated:
      return "CurriculumsGradeUpdated";
    case EventType.CurriculumsGradeDeleted:
      return "CurriculumsGradeDeleted";
    case EventType.CurriculumsGradeSubjectsAssigned:
      return "CurriculumsGradeSubjectsAssigned";
    case EventType.CurriculumsSubjectCreated:
      return "CurriculumsSubjectCreated";
    case EventType.CurriculumsSubjectUpdated:
      return "CurriculumsSubjectUpdated";
    case EventType.CurriculumsSubjectDeleted:
      return "CurriculumsSubjectDeleted";
    case EventType.CurriculumsStageCreated:
      return "CurriculumsStageCreated";
    case EventType.CurriculumsStageUpdated:
      return "CurriculumsStageUpdated";
    case EventType.CurriculumsStageDeleted:
      return "CurriculumsStageDeleted";
    case EventType.CurriculumsStagePublished:
      return "CurriculumsStagePublished";
    case EventType.CurriculumsStageUnPublished:
      return "CurriculumsStageUnPublished";
    case EventType.CurriculumsStageQuestionCreatedTypeConnectTheDots:
      return "CurriculumsStageQuestionCreatedTypeConnectTheDots";
    case EventType.CurriculumsStageQuestionCreatedTypeFillInThe3Blanks:
      return "CurriculumsStageQuestionCreatedTypeFillInThe3Blanks";
    case EventType.CurriculumsStageQuestionCreatedTypeFillInTheBlank:
      return "CurriculumsStageQuestionCreatedTypeFillInTheBlank";
    case EventType.CurriculumsStageQuestionCreatedTypePhotoMcqAnswerText:
      return "CurriculumsStageQuestionCreatedTypePhotoMcqAnswerText";
    case EventType.CurriculumsStageQuestionCreatedTypePhotoTrueOrFalse:
      return "CurriculumsStageQuestionCreatedTypePhotoTrueOrFalse";
    case EventType.CurriculumsStageQuestionCreatedTypeSortPhoto:
      return "CurriculumsStageQuestionCreatedTypeSortPhoto";
    case EventType.CurriculumsStageQuestionCreatedTypeSortText:
      return "CurriculumsStageQuestionCreatedTypeSortText";
    case EventType.CurriculumsStageQuestionCreatedTypeSpell:
      return "CurriculumsStageQuestionCreatedTypeSpell";
    case EventType.CurriculumsStageQuestionCreatedTypeTextAnswerNumber:
      return "CurriculumsStageQuestionCreatedTypeTextAnswerNumber";
    case EventType.CurriculumsStageQuestionCreatedTypeTextAnswerText:
      return "CurriculumsStageQuestionCreatedTypeTextAnswerText";
    case EventType.CurriculumsStageQuestionCreatedTypeTextMcqAnswer2Photo:
      return "CurriculumsStageQuestionCreatedTypeTextMcqAnswer2Photo";
    case EventType.CurriculumsStageQuestionCreatedTypeTextMcqAnswer3Photo:
      return "CurriculumsStageQuestionCreatedTypeTextMcqAnswer3Photo";
    case EventType.CurriculumsStageQuestionCreatedTypeTextMcqAnswer4Photo:
      return "CurriculumsStageQuestionCreatedTypeTextMcqAnswer4Photo";
    case EventType.CurriculumsStageQuestionCreatedTypeTextMcqAnswer6Photo:
      return "CurriculumsStageQuestionCreatedTypeTextMcqAnswer6Photo";
    case EventType.CurriculumsStageQuestionCreatedTypeTextMcqAnswer8Photo:
      return "CurriculumsStageQuestionCreatedTypeTextMcqAnswer8Photo";
    case EventType.CurriculumsStageQuestionCreatedTypeTextMcqAnswerText:
      return "CurriculumsStageQuestionCreatedTypeTextMcqAnswerText";
    case EventType.CurriculumsStageQuestionCreatedTypeTextTrueOrFalse:
      return "CurriculumsStageQuestionCreatedTypeTextTrueOrFalse";
    case EventType.CurriculumsConfigStageLimitsChanged:
      return "CurriculumsConfigStageLimitsChanged";
    case EventType.GamesDifficultyCreated:
      return "GamesDifficultyCreated";
    case EventType.GamesDifficultyUpdated:
      return "GamesDifficultyUpdated";
    case EventType.GamesDifficultyDeleted:
      return "GamesDifficultyDeleted";
    case EventType.GamesDifficultyEnabled:
      return "GamesDifficultyEnabled";
    case EventType.GamesDifficultyDisabled:
      return "GamesDifficultyDisabled";
    default:
      return "Unknown";
  }
};

export const formateAuditResponse = (type: Projection, content: string) => {
  switch (type) {
    case Projection.Admin:
      return JSON.parse(content) as AdminProjection;
    case Projection.Country:
      return JSON.parse(content) as CountryProjection;
    case Projection.City:
      return JSON.parse(content) as CityProjection;
    case Projection.School:
      return JSON.parse(content) as SchoolProjection;
    case Projection.Grade:
      return JSON.parse(content) as GradeProjection;
    case Projection.Subject:
      return JSON.parse(content) as SubjectProjection;
    case Projection.Stage:
      return JSON.parse(content) as StageProjection;
    case Projection.StageLimit:
      return JSON.parse(content) as StageLimitProjection;
    case Projection.Difficulty:
      return JSON.parse(content) as DifficultyProjection;
    case Projection.Badge:
      return JSON.parse(content) as BadgeProjection;
    case Projection.Quiz:
      return JSON.parse(content) as QuizProjection;
    case Projection.User:
      return JSON.parse(content) as UserProjection;
    case Projection.None:
      return JSON.parse(content) as any;
    default:
      return JSON.parse(content) as any;
  }
};

export function downloadFile(link: string) {
  var element = document.createElement("a");
  element.setAttribute("href", link);
  element.setAttribute("target", "_blank");

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function isPathFromLocalFiles(path: string): boolean {
  return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
    path
  );
}

export const getVideoDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result as string);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });
};
