<template>
  <nav
    :class="
      is_active
        ? 'translate-x-0 '
        : 'rtl:translate-x-full ltr:-translate-x-full'
    "
    class="fixed z-20 flex flex-col h-screen hiddenScrollbar overflow-auto transition-transform duration-150 ease-in transform bg-white shadow-xl ltr:left-0 rtl:right-0 text-primaryDark lg:static shadow-primary/10"
  >
    <div class="flex items-center justify-between px-5 my-2">
      <router-link to="/">
        <Image
          src="/logo.svg"
          class="object-cover h-[80px] w-[80px] mx-auto "
          alt="enjaz"
          loading="lazy"
        />
      </router-link>
      <div class="flex items-center justify-center gap-3">
        <h6 class="text-lg font-normal lg:text-2xl">
          {{ $t("enjaz") }}
        </h6>
        <span class="font-light text-[10px] whitespace-nowrap">{{
          $t("control-panel")
        }}</span>
      </div>
      <button class="lg:hidden" @click="toggleSidebar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style="fill: rgba(0, 0, 0, 1)"
        >
          <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
        </svg>
      </button>
    </div>
    <ul class="flex flex-col justify-center gap-5 mt-12 bg-white">
      <li class="block w-full" v-for="(link, index) in navLinks" :key="index">
        <RouterLink
          :to="{ name: link.route_name }"
          class="flex items-center justify-start px-5 py-3 text-sm whitespace-pre-wrap gap-3"
          :class="
            router.currentRoute.value.name == link.route_name
              ? 'text-primary bg-tertiary font-bold'
              : 'text-primaryDark'
          "
        >
          <span>
            <i
              v-if="link.icon"
              :class="link.icon"
            ></i>
          </span>
          <span>{{ link.title }}</span>
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>
<script lang="ts" setup>
import navLinks from "@/core/constant/navLinks";
import { useRouter } from "vue-router";

defineProps<{
  is_active: boolean;
}>();

const emit = defineEmits(["toggleSidebar"]);
const router = useRouter();

const toggleSidebar = () => {
  emit("toggleSidebar");
};
</script>
