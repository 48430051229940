import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

export const route: RouteRecordRaw = {
  name: "packagesManagement",
  path: "/packages/",
  component: RouterView,
  redirect: { name: "selectPackageGrade" },
  meta: {
    breadcrumb: [{ title: t("packages"), routeName: "packagesManagement" }],
  },
  children: [
    {
      path: "select-grade",
      name: "selectPackageGrade",
      component: () => import("./screens/SelectGrade.vue"),
      meta: {
        breadcrumb: [
          { title: t("packages"), routeName: "packagesManagement" },
          { title: t("select-grade"), routeName: "selectGrade" },
        ],
      },
    },
    {
      path: "all-packages",
      name: "packages",
      component: () => import("./screens/Packages.vue"),
      meta: {
        breadcrumb: [
          { title: t("packages"), routeName: "packagesManagement" },
          { title: t("select-grade"), routeName: "selectGrade" },
          { title: t("all-packages"), routeName: "packages" },
        ],
      },
    },
    {
      path: "create-packages",
      name: "createPackages",
      component: () => import("./screens/CreatePackages.vue"),
      meta: {
        breadcrumb: [
          { title: t("packages"), routeName: "packagesManagement" },
          { title: t("select-grade"), routeName: "selectGrade" },
          { title: t("all-packages"), routeName: "packages" },
          { title: t("create-package"), routeName: "createPackages" },
        ],
      },
    },
    {
      path: "update-packages/:id",
      name: "updatePackage",
      component: () => import("./screens/UpdatePackage.vue"),
      meta: {
        breadcrumb: [
          { title: t("packages"), routeName: "packagesManagement" },
          { title: t("select-grade"), routeName: "selectGrade" },
          { title: t("all-packages"), routeName: "packages" },
          { title: t("update-package"), routeName: "updatePackage" },
        ],
      },
    },
    {
      path: "view-package/:id",
      name: "viewPackage",
      component: () => import("./screens/ViewPackage.vue"),
      meta: {
        breadcrumb: [
          { title: t("packages"), routeName: "packagesManagement" },
          { title: t("select-grade"), routeName: "selectGrade" },
          { title: t("all-packages"), routeName: "packages" },
          { title: t("package-details"), routeName: "viewPackage" },
        ],
      },
    },
    {
      path: "add-course-to-package/:id",
      name: "addCourseToPackage",
      component: () => import("./screens/AddCourseToPackage.vue"),
      meta: {
        breadcrumb: [
          { title: t("packages"), routeName: "packagesManagement" },
          { title: t("select-grade"), routeName: "selectGrade" },
          { title: t("all-packages"), routeName: "packages" },
          { title: t("package-details"), routeName: "viewPackage" },
          {
            title: t("add-course-to-package"),
            routeName: "addCourseToPackage",
          },
        ],
      },
    },
    {
      path: "add-package-subscription/:id",
      name: "addPackageSubscription",
      component: () => import("./screens/AddPackageSubscription.vue"),
      meta: {
        breadcrumb: [
          { title: t("packages"), routeName: "packagesManagement" },
          { title: t("select-grade"), routeName: "selectGrade" },
          { title: t("all-packages"), routeName: "packages" },
          { title: t("package-details"), routeName: "viewPackage" },
          {
            title: t("add-package-subscription"),
            routeName: "addPackageSubscription",
          },
        ],
      },
    },
    {
      path: "update-package-subscription/:packageId/:subscriptionId",
      name: "updatePackageSubscription",
      component: () => import("./screens/UpdatePackageSubscription.vue"),
      meta: {
        breadcrumb: [
          { title: t("packages"), routeName: "packagesManagement" },
          { title: t("select-grade"), routeName: "selectGrade" },
          { title: t("all-packages"), routeName: "packages" },
          { title: t("package-details"), routeName: "viewPackage" },
          {
            title: t("update-package-subscription"),
            routeName: "updatePackageSubscription",
          },
        ],
      },
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};
