import { t } from "@/core/i18n";
import { RouteRecordRaw, RouterView } from "vue-router";

export const route: RouteRecordRaw = {
  path: "/vouchers-management/",
  name: "vouchersManagement",
  component: RouterView,
  redirect: { name: "vouchers" },
  meta: {
    breadcrumb: [{ title: t("vouchers-management"), routeName: "vouchers" }],
  },
  children: [
    {
      path: "vouchers",
      name: "vouchers",
      component: () => import("./screens/vouchers.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers-management"), routeName: "vouchersManagement" },
          { title: "vouchers", routeName: "vouchers" },
        ],
      },
    },
    {
      path: "create-voucher",
      name: "createVoucher",
      component: () => import("./screens/CreateVoucher.vue"),
      meta: {
        breadcrumb: [
          { title: t("vouchers-management"), routeName: "vouchersManagement" },
          { title: "vouchers", routeName: "vouchers" },
          { title: "create-voucher", routeName: "createVoucher" },
        ],
      },
    },
  ],
};
