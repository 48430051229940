import type { FirebaseOptions } from "firebase/app";

console.log(import.meta.env.MODE);
console.log(import.meta.env.VITE_BASE_URL);


export default {
  appName: "Enjaz",
  base_url: import.meta.env.VITE_BASE_URL,
  maxFileSize: 5 * 1024 * 1024, // 5MB`np
  MAPBOX_API_KEY:
    "pk.eyJ1IjoiZmFyYWpzaHVhaWIiLCJhIjoiY2tuMjFoeW1wMHJyODJwcGJscTllb29hMSJ9.bzjQzcDqVg9yG2fMehyLhw",
  firebaseConfig: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  } as FirebaseOptions,
};
