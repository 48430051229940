import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

import countries from "./countries";
import cities from "./cities";
import schools from "./schools";
import grades from "./grades";
import subjects from "./subjects";

const route: RouteRecordRaw = {
  path: "/subjects-and-grade/",
  name: "subjects-and-grade",
  component: RouterView,
  redirect: { name: "ListOfSubjects" },
  meta: {
    breadcrumb: [
      { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
    ],
  },
  children: [
    {
      path: "list-of-subjects",
      name: "ListOfSubjects",
      component: () => import("../screens/ListOfSubjects.vue"),
      meta: {
        // permission: 4,
        breadcrumb: [
          { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
          { title: t("ListOfSubjects"), routeName: "ListOfSubjects" },
        ],
      },
    },
    {
      path: "list-of-grades/",
      name: "ListOfGrades",
      meta: {
        // permission: 2,
      },
      component: RouterView,
      redirect: "/subjects-and-grade/list-of-grades/index",
      children: [
        {
          path: "index",
          name: "ListOfGrades",
          component: () => import("../screens/ListOfGrades.vue"),
          meta: {
            // permission: 2,
            breadcrumb: [
              { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
              { title: t("ListOfGrades"), routeName: "ListOfGrades" },
            ],
          },
        },
        {
          path: "grade-profile/:id",
          name: "GradeProfile",
          component: () => import("../screens/GradeProfile.vue"),
          meta: {
            // permission: 2,
            breadcrumb: [
              { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
              { title: t("ListOfGrades"), routeName: "ListOfGrades" },
              { title: t("grade-profile"), routeName: "GradeProfile" },
            ],
          },
        },
        {
          path: "grade-form-profile/:id",
          name: "GradeFormProfile",
          component: () => import("../screens/GradeFormProfile.vue"),
          meta: {
            // permission: 2,
            breadcrumb: [
              { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
              { title: t("ListOfGrades"), routeName: "ListOfGrades" },
              { title: t("grade-profile"), routeName: "GradeProfile" },
            ],
          },
        },
      ],
    },
    {
      path: "list-of-schools",
      name: "ListOfSchools",
      component: () => import("../screens/ListOfSchools.vue"),
      meta: {
        breadcrumb: [
          { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
          { title: t("ListOfSchools"), routeName: "ListOfSchools" },
        ],
      },
    },
    {
      path: "school-profile/:id",
      name: "SchoolProfile",
      component: () => import("../screens/SchoolProfile.vue"),
      meta: {
        breadcrumb: [
          { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
          { title: t("ListOfSchools"), routeName: "ListOfSchools" },
          { title: t("school-profile"), routeName: "SchoolProfile" },
        ],
      },
    },
    {
      path: "edit-school/:id",
      name: "EditSchool",
      component: () => import("../screens/EditSchool.vue"),
      meta: {
        breadcrumb: [
          { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
          { title: t("ListOfSchools"), routeName: "ListOfSchools" },
          { title: t("school-profile"), routeName: "SchoolProfile" },
          { title: t("edit-school"), routeName: "EditSchool" },
        ],
      },
    },
    {
      path: "list-of-countries",
      name: "ListOfCountries",
      component: () => import("../screens/ListOfCountries.vue"),
      meta: {
        breadcrumb: [
          { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
          { title: t("list-of-countries"), routeName: "ListOfCountries" },
        ],
      },
    },
    {
      path: "list-of-cities",
      name: "ListOfCities",
      component: () => import("../screens/ListOfCities.vue"),
      meta: {
        breadcrumb: [
          { title: t("SubjectsAndGrade"), routeName: "subjects-and-grade" },
          { title: t("list-of-cities"), routeName: "ListOfCities" },
        ],
      },
    },
  ],
};

export { route, countries, cities, schools, grades, subjects };
