<template>
  <div
    class="relative grid w-full h-screen grid-cols-12 text-gray-800 bg-accent"
  >
    <div class="relative col-span-full lg:col-span-2">
      <SideBar
        :is_active="sideBarActive"
        @toggleSidebar="sideBarActive = !sideBarActive"
      />
    </div>
    <main
      class="relative flex flex-col overflow-y-scroll transition-all ease-in duraltion-150 col-span-full lg:col-span-10 hiddenScrollbar"
    >
      <HeaderBar @toggleSidebar="sideBarActive = !sideBarActive" />
      <div class="flex items-start h-screen pt-[70px]">
        <MainContent />
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>
import SideBar from "./SideBar.vue";
import HeaderBar from "./HeaderBar.vue";
import MainContent from "./MainContent.vue";

import { ref, onMounted, watch } from "vue";

const sideBarActive = ref(true);
const notificationBarActive = ref(
  localStorage.getItem("liveNotification") == "1" ? true : false
);

const handleResize = () => {
  if (window.innerWidth > 1024) {
    sideBarActive.value = true;
  } else {
    sideBarActive.value = false;
  }
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

watch(notificationBarActive, (val) => {
  localStorage.setItem("liveNotification", val ? "1" : "0");
});
</script>

<style></style>
