import { RouteRecordRaw, RouterView } from "vue-router";

export const route: RouteRecordRaw = {
  path: "/system-settings",
  name: "SystemSettings",
  component: RouterView,
  redirect: { name: "SystemSettingsList" },
  meta: {
    breadcrumb: [{ title: "System Settings", routeName: "SystemSettings" }],
  },
  children: [
    {
      path: "",
      name: "SystemSettingsList",
      component: () => import("./screens/SystemSettings.vue"),
      meta: {
        breadcrumb: [
          { title: "System Settings", routeName: "SystemSettings" },
          { title: "List", routeName: "SystemSettingsList" },
        ],
      },
    },
    {
      path: "create",
      name: "createSystemSetting",
      component: () => import("./screens/CreateSystemSettings.vue"),
      meta: {
        breadcrumb: [
          { title: "System Settings", routeName: "SystemSettings" },
          { title: "List", routeName: "SystemSettingsList" },
          { title: "Create", routeName: "createSystemSetting" },
        ],
      },
    },
  ],
};
